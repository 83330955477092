import { css } from 'styled-components'
import theme from './theme'

const global = css`
  body {
    overflow-x: hidden;
    background-color: ${theme.colors.background};
    color: ${theme.colors.text};
  }

  p, li {
    font-size: 1.1em;
    line-height: 1.5;
  }
`

export default global