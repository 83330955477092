import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"


const Wrapper = styled.div`
`

const LangSwitch = () => (
  <Wrapper>
    <Link to="/">Español</Link>
    {` `}/{` `}
    <Link to="/ca">Català</Link>
  </Wrapper>
)

export default LangSwitch
