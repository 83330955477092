/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"  
import tw from 'tailwind.macro'
import { createGlobalStyle }  from "styled-components"
import theme from '../../config/theme'
import global from '../../config/global'

import Header from "./header"
import Footer from './footer'
import background from '../images/hero-background.jpg'
import "./layout.css"

const GlobalStyles = createGlobalStyle`
  html { 
    font-size: 19px;
    @media (max-width: ${theme.breakpoints[0]}) {
      font-size: 16px;
    }
  }
  body {
    background-image: url(${background});
    background-size: 100%;
    background-repeat: no-repeat;
    @media (max-width: ${theme.breakpoints[3]}) {
      background-position-y: 30vh;
    }
    @media (max-width: ${theme.breakpoints[1]}) {
      background-position-y: 30vh;
    }
    @media (max-width: ${theme.breakpoints[0]}) {
      background-position-y: 50vh;
    }
  }
  .inner {
    ${tw`px-2 py-10 md:px-6 lg:px-12`};
    max-width: ${theme.container};
    margin-left: auto;
    margin-right: auto;
  }
  p, li {
    max-width: 40rem;
  }
  ${global}
`

const Layout = ({ children, location, simple }) => (
  <>
    <GlobalStyles/>
    {!simple ? <Header location={location}/> : null}
    <main>
      {children}
    </main>
    {!simple ? <Footer location={location}/> : null}
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
