import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import theme from '../../config/theme'

const Wrapper = styled(Link)`
  display: inline-block;
  font-size: .85em;
  padding: .4rem 1rem;
  border: .05em solid transparent;
  background-color: ${props => props.invert ? theme.colors.primary : theme.colors.secondary };
  color: ${props => props.invert ? '#fff': theme.colors.primary };
  font-weight: bold;
  border-radius: .3rem;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: all 200ms ease-in-out;
  :hover {
    background-color: ${props => props.invert ? theme.colors.primary50 : theme.colors.secondary50};
  }
  ${props => props.transparent ? `
    background-color: transparent;
    border-color: ${theme.colors.primary};
    :hover {
      background-color: ${theme.colors.primary};
      color: white;
    }
    ` : null};
`

const LinkStyled = styled.a`
  display: inline-block;
  padding: .4rem 1rem;
  border: .05em solid transparent;
  background-color: ${props => props.invert ? theme.colors.primary : theme.colors.secondary };
  color: ${props => props.invert ? '#fff': theme.colors.primary };
  font-weight: bold;
  border-radius: .3rem;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: all 200ms ease-in-out;
  :hover {
    background-color: ${props => props.invert ? theme.colors.primary50 : theme.colors.secondary50};
  }
  ${props => props.transparent ? `
    background-color: transparent;
    border-color: ${theme.colors.primary};
    ` : null};
`

const Button = ({ children, invert, to, transparent, onclick }) => (
  <>
    {to.startsWith('/') ? (
      <Wrapper to={to} invert={invert} transparent={transparent} onClick={onclick}>
        {children}  
      </Wrapper>
    ) : (
      <LinkStyled href={to} invert={invert} transparent={transparent} onClick={onclick}>
        {children}
      </LinkStyled>
    )}
  </>
)

export default Button

Button.propTypes = {
  invert: PropTypes.bool
};