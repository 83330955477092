import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import tw from 'tailwind.macro'

import SEO from '../components/seo'
import Layout from '../components/layout'
import { Title } from '../components/titles'

const GlobalStyles = createGlobalStyle`
  body {
    background-image: none !important;
  }
`

const Articulo = styled.article`
  ${tw`px-2 md:px-6 lg:px-12 mb-20 relative z-50`};
  font-size: .6em;
  h3 {
    font-size: 2em;
  }
  h4 {
    font-size: 1.8em;
  }
  p + h3 {
    margin-top: 3.5em;
  }
`

const Fail = (props) => {
  return (
    <>
      <GlobalStyles/>
      <SEO title="Aviso Legal" description="Aviso legal y Privacidad"/>
      <Layout location={props.location}>
        <Articulo>
          <Title>Aviso Legal y Privacidad</Title>
          <small style={{display: 'block', marginBottom: '2rem'}}>Última actualización: 18 de Mayo de 2018</small>
          <p>Jornada10, marca propiedad de Clicksound SL, es una aplicación para el registro horario de los trabajadores. CLICKSOUND SL («Clicksound», «nosotros», «nuestro», «nos») como propietaria y responsable del tratamiento de los datos, gestiona y tramita nuestro sitio, nuestro sitio para móviles y las aplicaciones vinculadas (conjuntamente, nuestro «sitio»).</p>
          <p>Al acceder a nuestro sitio y usar nuestros servicios, acepta usted que ha leído y que entiende la presente Política de privacidad, así como las prácticas para la recopilación y gestión de la información que en ella se describe.</p>
          <p>Responsable: CLICKSOUND SL - NIF: B66120890  Dir. postal: C/ Pallars 141-143, 4-B, 08018, Barcelona. Teléfono: +34664426611 Correo electrónico: hola@jornada.io</p>
          <p>En las siguientes cláusulas se detalla, para su información, los detalles referentes a qué información recopilamos sobre usted, cómo utilizamos esta información, con quién es compartida, durante cuánto tiempo se almacena y sus derechos de supresión.</p>
          <p>En absolutamente todos los casos esta información es gestionada meramente con el objetivo de llevar a cabo las acciones comerciales de venta, post-venta y publicidad, siempre y cuando usted haya elegido que así sea. Toda la información almacenada sobre su persona o empresa podrá ser suprimida bajo petición expresa.</p>
          <h3>TRATAMIENTO DE DATOS DE CLIENTES</h3>
          <p>​
          En nombre de la empresa tratamos la información que nos facilita con el fin de prestarles el servicio solicitado, realizar la facturación del mismo. Los datos proporcionados se conservarán mientras se mantenga la relación comercial o durante los años necesarios para cumplir con las obligaciones legales. Los datos no se cederán a terceros salvo en los casos en que exista una obligación legal. Usted tiene derecho a obtener confirmación sobre si en CLICKSOUND SL estamos tratando sus datos personales por tanto tiene derecho a acceder a sus datos personales, rectificar los datos inexactos o solicitar su supresión cuando los datos ya no sean necesarios.
          ​</p>
          <h3 id="tratamiento-de-datos-de-clientes-potenciales">TRATAMIENTO DE DATOS DE CLIENTES POTENCIALES</h3>
          <p>​
          En nombre de la empresa tratamos la información que nos facilita con el fin de enviarle publicidad relacionada con nuestros productos y servicios por cualquier medio (postal, email o teléfono) e invitarle a eventos organizados por la empresa. Los datos proporcionados se conservarán mientras no solicite el cese de la actividad. Los datos no se cederán a terceros salvo en los casos en que exista una obligación legal. Usted tiene derecho a obtener confirmación sobre si en CLICKSOUND SL estamos tratando sus datos personales por tanto tiene derecho a acceder a sus datos personales, rectificar los datos inexacto o solicitar su supresión cuando los datos ya no sean necesarios para los fines que fueron recogidos”.
          ​</p>
          <h3 id="registro-de-actividades-de-tratamiento">REGISTRO DE ACTIVIDADES DE TRATAMIENTO</h3>
          <p>​</p>
          <h4 id="tratamiento-clientes">Tratamiento: Clientes</h4>
          <p>​</p>
          <ul>
            <li>Finalidad del tratamiento: Gestión de la relación con los clientes</li>
            <li>Descripción de las categorías de clientes y de las categorías de datos personales:
              <ul>
                <li><em>Clientes:</em> Personas con las que se mantiene una relación comercial como clientes y usan la aplicación para el registro de jornada de sus trabajadores.</li>
                <li><em>Categorías de datos personales:</em> Los necesarios para el mantenimiento de la relación comercial y uso de la aplicación. </li>
                <li><em>De identificación:</em> Nombre y apellidos del responsable. Nombre y CIF de la emprsa, dirección postal, teléfonos, e-mail.</li>
                <li><em>De identificación de los trabajadores:</em> Nombre y apellidos, NIF, horario de trabajo.</li>
                <li><em>Datos bancarios:</em> del cliente para la domiciliación de pagos junto a la autorización SEPA.</li>
                <li><em>Las categorías de destinatarios a quienes se comunicaron o comunicarán los datos personales:</em> Administración tributaria</li>
                <li><em>Cuando sea posible, los plazos previstos para la supresión de las diferentes categorías de datos:</em> Los previstos por la legislación fiscal respecto a la prescripción de responsabilidades.​</li>
              </ul>
            </li>
            ​<h4 id="tratamiento-potenciales-clientes">Tratamiento: Potenciales Clientes</h4>
            <li><em>Finalidad del tratamiento:</em> Gestión de la relación con los potenciales clientes</li>
            <li>Descripción de las categorías de potenciales clientes y de las categorías de datos personales:
              <ul>
                <li><em>Potenciales clientes:</em> Personas con las que se busca mantener una relación comercial como clientes.</li>
                <li><em>Categorías de datos personales:</em> Los necesarios para el mantenimiento de la relación comercial.</li>
                <li><em>De identificación:</em> Nombre y apellidos de un responsable. Nombre de la empresa, telèfono y e-mail.</li>
                <li><em>Las categorías de destinatarios a quienes se comunicaron o comunicarán los datos personales:</em> No se contempla.</li>
                <li><em>Cuando sea posible, los plazos previstos para la supresión de las diferentes categorías de datos:</em> Un año desde el primer contacto.</li>
              </ul>
            </li>
          </ul>
          ​
          ​<h3 id="condiciones-de-venta">CONDICIONES DE VENTA</h3>
          ​<h4 id="introducci-n">Introducción</h4>
          <p>Todo pedido hecho a CLICKSOUND SL, directamente o bajo la marca comercial JORNADA10, implica necesariamente la aceptación total y sin reservas, por parte del Cliente, de las Condiciones Generales de Venta que aquí se detallan. Además, el Cliente reconoce que la aceptación de las presentes condiciones implicará la aplicación de éstas al pedido al que se refieren, así como a todo pedido posterior, con excepción de aquellos casos en que nuevas condiciones sean puestas en su conocimiento por CLICKSOUND SL.</p>
          ​
          <p>Ley aplicable y jurisdicción competente. La interpretación y la ejecución de las condiciones, así como todos los actos que se sean consecuencia de ellas, quedarán sometidos al derecho español, salvo que existan disposiciones de orden público contrarias a ello.</p>

          ​<h4 id="el-producto">El Producto</h4>
          ​
          <p>El producto es una aplicación web, en modelo de SaaS (Software as a Service). Junto a la aplicación, bajo petición del cliente, se puede proporcionar un dispositivo electrónico, manufacturado por CLICKSOUND, para la comodidad del uso de la aplicación. Dicho dispositivo se conecta a Internet, mediante la wifi del cliente, y sus trabajadores pueden fichar su jornada mediante llaveros y/o targetas contactless que también proporciona y configura CLICKSOUND junto al dispositivo.</p>
          ​<h4 id="precios-y-planes">Precios y Planes</h4>
          ​
          <p>Los precios publicados en este sitio web se muestran en EUROS y son vigentes, a excepción de errores tipográficos evidentes. Todos los precios llevan el IVA incluido aplicable en el día del pedido, cualquier cambio de tasa de IVA será aplicado automáticamente a los precios de los productos en venta.</p>
          ​
          <p>El software se paga por cuotas (planes) dependiendo de su uso y del nombre de trabajadores.</p>

          ​<h4 id="diferentes-modos-de-pago">Diferentes modos de Pago</h4>
          ​
          <p>Al formular el pedido, el cliente puede elegir cómo quiere pagar las cuotas y el dispositivo contactless (si lo solicita).</p>

          <li>Mediante transferencia bancaria: pago anual, trimestral o semestral.</li>
          <li>Mediante domiciliación bancaria (con autorización SEPA: pago anual, trimestral o semestral.</li>
          ​<h4 id="env-os">Envíos</h4>
          ​
          <li>En caso de tener que enviar el dispositivo contactless (con llaveros y/o targetas) por correo postal:</li>
          <li>Los productos adquiridos se entregarán a la persona y a la dirección facilitada por la empresa.</li>
          <li>La demora en la entrega del producto no implicará ni anulación del pedido ni indemnización alguna.</li>
          <li>Un pedido se considera entregado en el momento en que el transportista entrega el paquete o los paquetes al cliente y este firma el documento de recepción de entrega.</li>
          <li>Corresponde al cliente verificar el estado de la mercancía a la recepción e indicar todas las anomalías en el albarán de recepción de entrega.</li>
          ​<h4 id="pol-ticas-de-devoluciones-y-anulaciones">Políticas de devoluciones y anulaciones</h4>
          ​<h3 id="devoluci-n-de-la-mercanc-a">Devolución de la mercancía</h3>
          ​
          <li>En caso de que la mercancía sea defectuosa y el cliente pueda proporcionar pruebas irrefutables durante los 3 primeros días tras su recepción, se procederá al reembolso del producto y la vuelta de los dispotivos a las dependencias de CLICKSOUND.</li>
          ​<h3 id="anulaci-n-de-pedido">Anulación de pedido</h3>
          ​
          <li>En caso de no quedar convencido con el software de registro de jornada laboral, este podrá ser anulado en cualquier momento previa notificación a CLICKSOUND mediante el e-mail: hola@jornada.io o el teléfono de contacto. </li>
          <li>En caso de anulación, se facturará la quota entera del mes en vigor. Se finalizará el mes y se dará de baja la plataforma. </li>
          <li>En caso de envío de dispositivos: Si la anulación del pedido llega antes de la salida de mercancía el cliente no tendrá ningún gasto. En el caso de una anulación después de la salida del pedido, el cliente tendrá a su cargo los gastos de envío así como los gastos derivados de la recuperación del pedido por parte de CLICKSOUND.</li>
      

        </Articulo>
      </Layout>
    </>
  )
} 

export default Fail