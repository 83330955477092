import React from "react"
import styled from "styled-components"
import Navigation from "./navigation"

import Logo from './logo'

const Flex = styled.div`
  display: flex;
  align-items: center;
`

const Header = ({location}) => (
  <div className="inner">
    <Flex>
      <Logo/>
      <Navigation location={location}/>
    </Flex>
  </div>
)

export default Header
