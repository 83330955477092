
import React, { useState } from "react"
import styled from "styled-components"
import Hamburger from './hamburger'
import Button from './buttons'
import theme from '../../config/theme'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'


const Trigger = styled.div`
  position: relative;
  z-index: 2;
  display: none;
  // @media (min-width: ${theme.breakpoints[1]}) {
  //   display: none;
  // }
`
    
const Menu = styled.nav`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
  padding-left: 1.5rem;
  a, button {
    &:not(:last-child) {
      margin-right: 3rem;
    }
  }
  // @media (max-width: ${theme.breakpoints[1]}) {
  //   visibility: ${props => props.menu ? 'visible' : 'hidden'};
  //   transform: ${props => props.menu ? 'translateY(0)' : 'translateY(200%)'};
  //   transition: all 200ms ease-in-out;
  //   position: absolute;
  //   top: 5rem;
  //   flex-wrap: wrap;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
  //   background-color: white;
  //   width: calc(100% - 1rem);
  //   height: calc(100vh - 10rem);
  //   z-index: 100;
  //   border-radius: 1rem;
  //   a {
  //     margin-right: 0 !important;
  //     margin-bottom: 2rem;
  //     font-size: 1.5rem;
  //   }
  // }
`

// const MenuItems = styled.div`
//   display: flex;
//   align-items: center;
//   margin-left: 1rem;
// `

// const MenuItem = styled(Link)`
//   text-decoration: none;
//   font-weight: bold;
//   color: inherit;
//   &:hover {
//     color: ${theme.colors.primary};
//   }
// `

const Navigation = ({location}) => {
  const [ menu, toggle ] = useState(false)
  return (
    <>
      <Trigger onClick={() => toggle(!menu)}> 
        <Hamburger close={!menu}/>
      </Trigger>
      <Menu menu={menu}>
        {/ca/.test(location.pathname) ? (
          <>
            {/* <MenuItem to="/cat">Producte</MenuItem>
            <MenuItem to="/cat/plans">Plans</MenuItem> */}
            <Button 
              to="/ca/demostracio" 
              transparent="true"
              onClick={e => {
                // To stop the page reloading
                e.preventDefault()
                // Lets track that custom click
                trackCustomEvent({
                  // string - required - The object that was interacted with (e.g.video)
                  category: "Landing",
                  // string - required - Type of interaction (e.g. 'play')
                  action: "demostracion_header_btn",
                })
              }}
            >Prova la demostració</Button>
          </>
        ) : (
          <>
            {/* <MenuItems>
              <MenuItem to="/">Funcionalidad</MenuItem>
              <MenuItem to="/planes">Planes y servicios</MenuItem>
            </MenuItems> */}
            <Button
              to="/demostracion"
              transparent="true"
              onClick={() => {
                // Lets track that custom click
                trackCustomEvent({
                  category: "Landing",
                  action: "demostracion_header_btn",
                })
              }}
            >Prueba la demostración</Button>
          </>
        )}
      </Menu>
    </>
  )
}

export default Navigation

