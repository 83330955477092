import React from "react"
import styled from "styled-components"
import tw from 'tailwind.macro'
import { Link } from "gatsby"
import theme from '../../config/theme'

import SVG from '../components/svg'
import LangSwitch from './lang-switch'

const Wrapper = styled.div`
  ${tw`flex flex-wrap p-5 md:p-6 lg:p-12`}
  font-size: 18px;
  color: white;
  p, a {
    margin-bottom: .3em !important;
  }
  p, a {
    font-size: .8em;
  }
  background-color: ${theme.colors.primary};
  a {
    font-weight: bolder;
    text-decoration: none;
    color: white;
  }
  a:hover {
    text-decoration: underline;
  }
  nav a {
    display: block;
    margin-bottom: .8rem !important;
  }
  h4 {
    margin-bottom: 1em;
  }
  p + small,
  a + small {
    margin-top: .8em;
  }
  small {
    display: block;
    margin-bottom: .3em;
    font-weight: bold;
  }
`

const Column = styled.div`
  ${tw`w-auto mb-10 pr-0 md:w-1/3 md:mb-0 md:pr-12`}
`

const Bottom = styled.div`
  ${tw`mt-10`}
`

const Logo = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  letter-spacing: .02em;
  font-weight: bold;
  text-decoration: none;
  padding-top: .5rem;
  padding-bottom: 1rem;
  position: relative;
  z-index: 2;
  svg {
    display: block;
    position: inherit;
    width: 1.5em;
    margin-right: .3em;
  }
  ::after {
    content: 'IO';
    font-size: .5em;
    position: relative;
    top: -.8em;
    left: .1em;
    text-transform: uppercase;
  }
`

const Footer = ({location}) => {
  const catala = /ca/.test(location.pathname)
  return (
    <Wrapper>
      <Column>
        <Logo>
          <SVG icon="logo" width="5"/>
          Jornada
        </Logo>
        {catala ? (
          <p>Aplicació per al registre de jornada laboral que compleix amb la normativa legal espanyola vigent des de maig de 2019. Disposem de registre mitjançant contactless, targetes, clauers i empremta dactilar.</p>
          ) : (
            <p>Aplicación para el registro de jornada laboral que cumple con la normativa legal española vigente desde mayo de 2019. Disponemos de registro mediante contactless, targetas, llaveros y huella dactilar.</p>
          ) 
        }
        <small>Idioma</small>
        <LangSwitch/>
      </Column>
      <Column>
        <h4>{catala ? 'Informació de contacte' : 'Información de contacto'}</h4>
        <small>{catala ? 'Telèfon' : 'Teléfono'}</small>
        <a href="tel:0034679565019">(+34) 679 565 019</a>
        <small>Email</small>
        <a href="mailto:hola@jornada.io">hola@jornada.io</a><br />

        <br />
        <p><b>Barcelona</b></p>
        <p>Pallars 141-143, 4B – 08018</p>
        <p><em>Poblenou 22@Barcelona</em></p>
        
        <br/>
        <p><b>Vic</b></p>
        <p>Carrer de la Llotja – 08500 Vic</p> 
        {catala ? (
            <>
              <p><em>Dins del VIT – Integració Tecnològica (MAF S.I.)</em></p>
            </>
          ) : (
            <>
              <p><em>En el VIT – Integració Tecnològica (MAF S.I.)</em></p>
            </>
          )}
            
      </Column>
      <Column>
        <h4>{catala ? 'Navegació' : 'Navegación'}</h4>
        <nav>
          {catala ? (
            <>
              <Link to="/cat">Producte</Link>
              <Link to="/cat/plans">Plans</Link>
              <Link to="/cat/demostracio">Demostració</Link>
              <Link to="/nota-legal">Aviso Legal</Link>
            </>
          ) : (
            <>
              <Link to="/">Producto</Link>
              <Link to="/registro">Empieza ahora</Link>
              <Link to="/demostracion">Demostración</Link>
              <Link to="/nota-legal">Aviso Legal</Link>
            </>
          )}
        </nav>
      </Column>
      <Bottom>
        <p>© {new Date().getFullYear()}, {catala ? 'Producte desenvolupat per' : 'Producto desarrollado por'} <a href="https://factura10.com" target="_blank" rel="noopener noreferrer">Factura10</a></p>
      </Bottom>
    </Wrapper>
  )
}

export default Footer