import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  padding: 1rem;
`

const Line = styled.span`
  width: 20px;
  height: 2px;
  background-color: black;
  display: block;
  margin-bottom: .3em;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  
  &:last-child {
    margin-bottom: 0;
  }
  ${({ close }) =>
    !close &&`

    &:nth-child(2){
      width: 0px;
    }

    &:nth-child(1),
    &:nth-child(3){
      transition-delay: 0.3s;
    }

    &:nth-child(1){
      transform: translateY(5px) rotate(-45deg);
    }

    &:nth-child(3){
      transform: translateY(-8px) rotate(45deg);
    }

  `}
`



const Hamburger = ({ close }) => (
  <Wrapper close={close}>
    <Line close={close}></Line>
    <Line close={close}></Line>
    <Line close={close}></Line>
  </Wrapper>
)

export default Hamburger

